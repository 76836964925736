import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useState } from 'react'

export type SearchBarParams = {
  initialValue?: string
  placeholder?: string
  onSubmit?: (value: string) => void
}

export const SearchBar = ({ initialValue, placeholder, onSubmit }: SearchBarParams) => {
  const [value, setValue] = useState(initialValue || '')

  return (
    <div className="flex flex-row items-stretch gap-4">
      <input
        type="text"
        name="order_id"
        id="order_id"
        className="px-4 focus:ring-sky-500 focus:border-sky-500 block flex-grow text-lg border-gray-200"
        placeholder={placeholder}
        value={value}
        onChange={(event) => setValue(event.target.value)}
        onKeyDown={(event) => {
          if (event.key === 'Enter') {
            onSubmit?.(value)
          }
        }}
      />
      <button
        type="submit"
        className="w-12 h-12 flex-shrink-0 border border-transparent font-medium text-lg text-white bg-sky-600 hover:bg-sky-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sky-500"
        onClick={(_) => onSubmit?.(value)}
      >
        <FontAwesomeIcon icon={faMagnifyingGlass} />
      </button>
    </div>
  )
}
