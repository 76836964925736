import { intlFormat, parseISO } from 'date-fns'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faBox,
  faBoxesPacking,
  faBoxesStacked,
  faChevronUp,
  faCircleExclamation,
  faClipboardCheck,
  faPlaneCircleCheck,
  faReplyAll,
  faSquare,
  faTruckArrowRight,
  faTruckFast,
  faUserCheck,
  faWarehouse,
  faXmarksLines,
} from '@fortawesome/free-solid-svg-icons'

import { Trace, TraceStatusType } from '../order'
import { useMemo } from 'react'

export type StatusItemParams = {
  trace: Trace
  className?: string
  latest?: boolean
}

export const StatusItem = ({ trace, className, latest = false }: StatusItemParams) => {
  const parsedTime = useMemo(() => parseISO(trace.status_time ?? ''), [trace.status_time])

  const { date, time } = useMemo(
    () =>
      !isNaN(parsedTime.valueOf())
        ? {
            date: intlFormat(
              parsedTime,
              {
                year: 'numeric',
                month: 'numeric',
                day: 'numeric',
              },
              { locale: 'ru' }
            ),
            time: intlFormat(
              parsedTime,
              {
                hour: 'numeric',
                minute: 'numeric',
              },
              { locale: 'ru' }
            ),
          }
        : { date: '', time: '' },
    [parsedTime]
  )

  const datetime = useMemo(
    () =>
      !isNaN(parsedTime.valueOf())
        ? intlFormat(
            parsedTime,
            {
              year: 'numeric',
              month: 'long',
              day: 'numeric',
              hour: 'numeric',
              minute: 'numeric',
            },
            { locale: 'ru' }
          )
        : '',
    [parsedTime]
  )

  return (
    <div
      className={`flex flex-row sm:items-center hover:bg-slate-50 transition-color ease-custom ${
        latest ? 'text-slate-900' : 'text-slate-600'
      } ${className}`}
    >
      <div
        className={`w-24 hidden sm:flex flex-col flex-shrink-0 items-end text-slate-600 text-sm ${latest && 'mt-1'}`}
      >
        <p>{date}</p>
        <p>{time}</p>
      </div>
      <div className="flex flex-col flex-shrink-0 w-16 sm:w-20 items-center py-1">
        {latest ? (
          <div className={`w-9 h-9 rounded-sm relative ${statusColor(trace.type_of_status)}`}>
            <FontAwesomeIcon
              icon={statusIcon(trace.type_icons)}
              className="text-white absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2"
            />
          </div>
        ) : (
          <div
            className={`w-4 h-4 sm:w-5 sm:h-5 group-hover:w-9 group-hover:h-9 transition-size ease-custom rounded-sm relative ${statusColor(
              trace.type_of_status
            )}`}
          >
            <FontAwesomeIcon
              icon={faChevronUp}
              className="text-xs text-white hidden sm:block absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 opacity-100 group-hover:opacity-0 transition-opacity ease-custom"
            />
            <FontAwesomeIcon
              icon={statusIcon(trace.type_icons)}
              className="text-white absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 opacity-0 group-hover:opacity-100 transition-opacity ease-custom"
            />
          </div>
        )}
      </div>
      <div className="pr-4">
        <p
          className={`font-medium ${
            latest
              ? 'text-xl mb-0.5'
              : 'pr-20 group-hover:scale-125 group-hover:pt-1.5 group-hover:mb-0.5 origin-bottom-left transition-all ease-custom'
          }`}
        >
          {trace.status_for_apps}
        </p>
        <p>{trace.description}</p>
        <p>{trace.store_name}</p>
        <p className="sm:hidden">{datetime}</p>
      </div>
    </div>
  )
}

const statusColor = (status?: string) => {
  switch (status) {
    case TraceStatusType.SUCCESS:
      return 'bg-green-600'
    case TraceStatusType.WARNING:
      return 'bg-yellow-600'
    case TraceStatusType.FAILURE:
      return 'bg-red-600'
    case TraceStatusType.INFO:
    case TraceStatusType.MAIN:
    default:
      return 'bg-sky-600'
  }
}

const statusIcon = (iconType?: number) => {
  switch (iconType) {
    case 1:
      return faBox
    case 2:
      return faBoxesStacked
    case 3:
      return faWarehouse
    case 4:
      return faTruckFast
    case 5:
      return faBoxesPacking
    case 6:
      return faTruckArrowRight
    case 7:
      return faClipboardCheck
    case 8:
      return faXmarksLines
    case 9:
      return faReplyAll
    case 10:
      return faCircleExclamation
    case 11:
      return faUserCheck
    case 12:
      return faPlaneCircleCheck
    default:
      return faSquare
  }
}
