export type RouteLineParams = {
  className?: string
}

export const RouteLine = ({ className }: RouteLineParams) => {
  return (
    <div className={`flex flex-row items-center ${className}`}>
      <div className="bg-sky-600 w-1.5 h-1.5 rounded-full translate-x-0.5" />
      <hr className="flex-grow border-sky-600" />
      <div className="bg-sky-600 w-1.5 h-1.5 rounded-full -translate-x-0.5" />
    </div>
  )
}
