export type TextCardParams = {
  className?: string
  icon: JSX.Element
  text: string
}

export const TextCard = ({ className, icon, text }: TextCardParams) => {
  return (
    <div className={`px-6 py-4 border flex flex-row sm:items-center gap-6 ${className}`}>
      <div className="w-4 mt-1 sm:mt-0 flex justify-center">{icon}</div>
      <p>{text}</p>
    </div>
  )
}
