import { Trace } from '../order'
import { StatusItem } from './status-item'

export type LatestTraceParams = {
  trace: Trace
  className?: string
}

export const LatestTrace = ({ trace, className }: LatestTraceParams) => {
  return (
    <div className={`border ${className}`}>
      <StatusItem trace={trace} latest={true} className="py-4" />
    </div>
  )
}
