import { faPallet } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export const PalletWithBoxes = () => {
  return (
    <div className="relative">
      <div className="w-2 h-2 absolute bottom-3 left-0.5 rounded-sm bg-current" />
      <div className="w-1.5 h-1.5 absolute bottom-3 left-3 rounded-sm bg-current" />
      <FontAwesomeIcon icon={faPallet} />
    </div>
  )
}
