import { faCircleXmark, faInfoCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useEffect, useMemo, useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { Loading } from './components/loading'
import { OrderCard } from './components/order-card'
import { SearchBar } from './components/search-bar'
import { TextCard } from './components/text-card'
import { TracesCard } from './components/traces-card'
import { LatestTrace } from './components/latest-trace'
import { Order } from './order'

// let defaultOrder: Order = {
//   id: 'order1',
//   address_outgoing_city: 'Атырау город',
//   address_outgoing_country: 'Казахстан',
//   address_outgoing_state: 'Атырауская область',
//   date_delivery_plan: '2022-08-28',
//   date_of_delivery: '2022-08-28',
//   date_ordered: '2022-08-22',
//   number: '5000000001',
//   recepient_company: 'Магазин Киев',
//   sender_company: 'Магазин Атырау',
//   shipping_address_city: 'Киев город',
//   shipping_address_country: 'Украина',
//   shipping_address_state: 'Киев город',
//   traces: [
//     {
//       id: 'trace6',
//       description: '',
//       final_status: true,
//       name: 'Доставлено',
//       name_advanced: 'DELIVERED',
//       status_for_apps: 'Отправление вручено получателю',
//       status_time: '2022-08-28T13:20:00+02:00',
//       store_name: 'Киев город',
//       type_icons: 3,
//       type_of_status: 'success',
//       visible: true,
//     },
//     {
//       id: 'trace5',
//       final_status: false,
//       name: 'Таможня',
//       name_advanced: 'DELIVERED',
//       status_for_apps: 'Отправление передано в доставку',
//       status_time: '2022-08-28T10:25:00+02:00',
//       store_name: 'Киев город',
//       type_icons: 3,
//       type_of_status: 'info',
//       visible: true,
//     },
//     {
//       id: 'trace4',
//       description: 'Посылка задержана на этапе таможенного контроля',
//       final_status: false,
//       name: 'Таможня',
//       name_advanced: 'DELIVERED',
//       status_for_apps: 'Требуется таможенная декларация',
//       status_time: '2022-08-26T08:45:00+02:00',
//       store_name: 'Киевская область',
//       type_icons: 3,
//       type_of_status: 'warning',
//       visible: true,
//     },
//     {
//       id: 'trace2',
//       description: '',
//       final_status: false,
//       name: 'Получен складом',
//       name_advanced: 'ACCEPTED',
//       status_for_apps: 'Отправление прибыло в центр сортировки',
//       status_time: '2022-08-23T09:30:00+06:00',
//       store_name: 'EMEX-АТЫРАУ',
//       type_icons: 3,
//       type_of_status: 'main',
//       visible: true,
//     },
//     {
//       id: 'trace1',
//       description: '',
//       final_status: false,
//       name: 'Новый',
//       name_advanced: 'NEW',
//       status_for_apps: 'Создано отправление',
//       status_time: '2022-08-22T23:45:00+06:00',
//       store_name: 'EMEX-Терминал (Алматы)',
//       type_icons: 1,
//       type_of_status: 'main',
//       visible: true,
//     },
//   ],
//   type_of_parcel: 'Документы',
//   type_of_service: 'Срочно',
//   weight: 0.5,
// }

export function App() {
  const { pathname, search } = useLocation()
  const navigate = useNavigate()

  const params = useMemo(() => new URLSearchParams(search), [search])

  const [orderId, setOrderId] = useState(params.get('id'))
  const [loading, setLoading] = useState(false)
  const [orderDetails, setOrderDetails] = useState<Order | null>(null)
  const [error, setError] = useState<string | null>(null)

  const fetchOrder = async (id: string) => {
    setError(null)
    setLoading(true)
    try {
      const response = await fetch(`${process.env.BACKEND_HOST}/api/v1/tracking/${id}`, {
        headers: { 'X-App-ID': process.env.APP_ID_TOKEN ?? '' },
        method: 'GET',
      })
      switch (response.status) {
        case 200:
          setOrderDetails(await response.json())
          break
        case 401:
        case 403:
          setError('У вас нет доступа к этим данным')
          break
        case 404:
          setError(`Заказ ${id} не найден`)
          break
        case 429:
          setError('Слишком много запросов, попробуйте позже')
          break
        case 500:
          setError('Внутренняя ошибка сервера')
          break
        default:
          setError('Возникла непредвиденная ошибка')
          break
      }
    } catch (error) {
      setError('Сервер недоступен, проверьте подключение к интернету')
    } finally {
      setLoading(false)
    }
  }

  // const fetchOrder = (_id: string) => {
  //   setLoading(true)
  //   setTimeout(() => {
  //     // setError('Возникла непредвиденная ошибка')
  //     setOrderDetails(defaultOrder)
  //     setLoading(false)
  //   }, 500)
  // }

  useEffect(() => {
    if (orderId != null && orderId !== '') {
      const newParams = new URLSearchParams(params)
      newParams.set('id', orderId)
      navigate(`${pathname}?${newParams.toString()}`, { replace: true })
    }
  }, [orderId])

  useEffect(() => {
    if (orderId != null && orderId !== '') {
      fetchOrder(orderId)
    }
  }, [])

  return (
    <div className="max-w-screen-md md:mx-auto p-4 sm:p-8">
      <SearchBar
        initialValue={orderId || ''}
        placeholder="Номер заказа"
        onSubmit={(id) => {
          setOrderId(id)
          fetchOrder(id)
        }}
      />
      {loading && <Loading className="mt-4" />}
      {error && (
        <TextCard
          className="mt-4"
          icon={<FontAwesomeIcon className="text-red-700" icon={faCircleXmark} />}
          text={error}
        />
      )}
      {orderDetails && (
        <>
          <OrderCard className="mt-4 bg-white" order={orderDetails} />
          <LatestTrace className="mt-4 bg-white" trace={orderDetails.traces[0]} />
          {orderDetails.traces.length > 1 && (
            <TracesCard className="mt-4 bg-white" traces={orderDetails.traces.slice(1)} />
          )}
          {orderDetails.traces.length > 0 && (
            <TextCard
              className="mt-4 bg-white"
              icon={<FontAwesomeIcon className="text-slate-700" icon={faInfoCircle} />}
              text="Дата и время отображены в часовом поясе вашего устройства"
            />
          )}
        </>
      )}
    </div>
  )
}
