export type LoadingProps = {
  className?: string
}

export const Loading = ({ className }: LoadingProps) => {
  return (
    <div className={`w-full h-12 flex justify-center items-center ${className}`}>
      <div className="w-4 h-4 rounded-full bg-sky-700 animate-ping" />
    </div>
  )
}
