import { intlFormat, parseISO } from 'date-fns'
import { useMemo } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faCube,
  faFileContract,
  faGlobe,
  faTruck,
  faTruckFast,
  faWeightHanging,
} from '@fortawesome/free-solid-svg-icons'

import { Order, Services, ShipmentTypes } from '../order'
import { Breadcrumb } from './breadcrumb'
import { RouteLine } from './route-line'
import { PalletWithBoxes } from './icons/pallet-with-boxes'

export type OrderCardParams = {
  order: Order
  className?: string
}

export const OrderCard = ({ className, order }: OrderCardParams) => {
  const startDate = useMemo(() => parseISO(order.date_ordered ?? ''), [order.date_ordered])
  const endDate = useMemo(
    () =>
      parseISO(
        order.date_of_delivery != null && order.date_of_delivery !== ''
          ? order.date_of_delivery
          : order.date_delivery_plan ?? ''
      ),
    [order.date_of_delivery, order.date_delivery_plan]
  )

  const formattedStartDate = useMemo(
    () =>
      !isNaN(startDate.valueOf())
        ? intlFormat(
            startDate,
            {
              year: 'numeric',
              month: 'long',
              day: 'numeric',
            },
            { locale: 'ru' }
          )
        : '',
    [startDate]
  )

  const formattedEndDate = useMemo(
    () =>
      !isNaN(endDate.valueOf())
        ? intlFormat(
            endDate,
            {
              year: 'numeric',
              month: 'long',
              day: 'numeric',
            },
            { locale: 'ru' }
          )
        : '',
    [endDate]
  )

  return (
    <div className={`p-4 border ${className}`}>
      <h2 className="mb-2 text-2xl">Заказ №{order.number}</h2>
      <div className="mt-4 flex flex-row flex-wrap gap-3">
        {order.type_of_parcel && <Breadcrumb icon={shipmentIcon(order.type_of_parcel)} text={order.type_of_parcel} />}
        {order.type_of_service && <Breadcrumb icon={serviceIcon(order.type_of_service)} text={order.type_of_service} />}
        {order.weight > 0 && (
          <Breadcrumb icon={<FontAwesomeIcon icon={faWeightHanging} />} text={`${order.weight.toFixed(1)} кг`} />
        )}
      </div>
      <div className="mt-6 hidden sm:block">
        <div className="my-1 flex flex-row items-center">
          <div className="text-left font-medium">
            <p>{order.address_outgoing_city}</p>
            {order.address_outgoing_country !== 'Казахстан' && <p>{order.address_outgoing_country}</p>}
          </div>
          <RouteLine className="flex-grow px-8" />
          <div className="text-right font-medium">
            <p>{order.shipping_address_city}</p>
            {order.shipping_address_country !== 'Казахстан' && <p>{order.shipping_address_country}</p>}
          </div>
        </div>
        <div className="flex flex-row justify-between text-slate-500">
          <p>{formattedStartDate}</p>
          <p>
            {(order.date_of_delivery == null || order.date_of_delivery === '') && '~'}
            {formattedEndDate}
          </p>
        </div>
      </div>
      <div className="mt-6 sm:hidden">
        <p className="mb-1 font-medium">Из</p>
        <p>
          {order.address_outgoing_city}
          {order.address_outgoing_country !== 'Казахстан' && `, ${order.address_outgoing_country}`}
        </p>
        <p className="text-slate-500">{formattedStartDate}</p>
        <p className="mt-4 mb-1 font-medium">До</p>
        <p>
          {order.shipping_address_city}
          {order.shipping_address_country !== 'Казахстан' && `, ${order.shipping_address_country}`}
        </p>
        <p className="text-slate-500">
          {(order.date_of_delivery == null || order.date_of_delivery === '') && '~'}
          {formattedEndDate}
        </p>
      </div>
    </div>
  )
}

const shipmentIcon = (shipmentType: string) => {
  switch (shipmentType) {
    case ShipmentTypes.PACKAGE:
      return <FontAwesomeIcon icon={faCube} />
    case ShipmentTypes.DOCUMENT:
      return <FontAwesomeIcon icon={faFileContract} />
    case ShipmentTypes.PALLET:
      return <PalletWithBoxes />
    default:
      return <FontAwesomeIcon icon={faCube} />
  }
}

const serviceIcon = (service: string) => {
  switch (service) {
    case Services.ECONOMY:
    case Services.KIOSK:
    case Services.PVZ:
    case Services.PICKUP:
      return <FontAwesomeIcon icon={faTruck} />
    case Services.EXPRESS:
    case Services.URGENT:
    case Services.KIOSK_EXPRESS:
      return <FontAwesomeIcon icon={faTruckFast} />
    default:
      return <FontAwesomeIcon icon={faGlobe} />
  }
}
