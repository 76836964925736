export type Order = {
  id: string
  number?: string

  type_of_parcel?: string
  type_of_service?: string

  date_ordered?: string
  date_delivery_plan?: string
  date_of_delivery?: string

  sender_company?: string
  address_outgoing_city?: string
  address_outgoing_state?: string
  address_outgoing_country?: string

  recepient_company?: string
  shipping_address_city?: string
  shipping_address_state?: string
  shipping_address_country?: string

  weight: number

  traces: Array<Trace>
}

export type Trace = {
  id: string
  name?: string
  description?: string
  name_advanced?: string
  status_for_apps?: string
  store_name?: string
  status_time?: string
  type_of_status?: string
  type_icons: number
  final_status: boolean
  visible: boolean
}

export const ShipmentTypes = {
  PACKAGE: 'Посылка',
  DOCUMENT: 'Документы',
  PALLET: 'Груз',
}

export const Services = {
  ECONOMY: 'Эконом',
  EXPRESS: 'Экспресс',
  URGENT: 'Срочно',
  KIOSK: 'Постамат',
  KIOSK_EXPRESS: 'Постамат (Экспресс)',
  PVZ: 'ПВЗ',
  PICKUP: 'Забор',
}

export const TraceStatusType = {
  SUCCESS: 'success',
  INFO: 'info',
  FAILURE: 'failure',
  WARNING: 'warning',
  MAIN: 'main',
}
