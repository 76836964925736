import { Trace } from 'src/order'
import { StatusItem } from './status-item'

export type TracesCardParams = {
  traces: Trace[]
  className?: string
}

export const TracesCard = ({ traces, className }: TracesCardParams) => {
  return (
    <div className={`border divide-y ${className}`}>
      {traces.map((trace) => (
        <StatusItem
          key={`trace-${trace.id}`}
          className="py-2 group transition-spacing ease-custom hover:py-4"
          trace={trace}
        />
      ))}
    </div>
  )
}
