export type BreadcrumbParams = {
  icon?: JSX.Element
  text: string
  className?: string
}

export const Breadcrumb = ({ className, icon, text }: BreadcrumbParams) => {
  return (
    <div className={`px-2 h-8 w-fit bg-sky-50 text-sky-700 flex flex-row items-center ${className}`}>
      {icon && <div className="mr-2">{icon}</div>}
      {text}
    </div>
  )
}
